<template>
  <div
    class="quali"
    :class="{ 'no-picture': !data.header.picture.src }"
    v-if="data"
  >
    <BackBtn class="quali__back" />
    <div class="quali-inner--large">
      <ArticleHeader :data="data.header" />

      <div class="quali__content">
        <div v-if="data.body.text">
          <h3>Description</h3>
          <div class="quali__content__text" v-html="data.body.text"></div>
        </div>

        <div
          v-if="
            data.body.adjectives && Object.keys(data.body.adjectives).length
          "
        >
          <h3>Adjectifs</h3>
          <ul>
            <li
              v-for="adjective in data.body.adjectives"
              :key="`adjective-${adjective.label}`"
            >
              {{ adjective.label }}
            </li>
          </ul>
        </div>

        <MailtoCta
          class="quali__content__cta"
          :data="completeQuali"
          :nameItem="data.header.title"
        />
      </div>
    </div>
    <div class="quali__extended" v-if="data.footer">
      <div class="quali-inner--large">
        <div class="quali__content">
          <h3>Suggestions de qualis</h3>

          <router-link
            v-for="item in data.footer"
            :key="item.url"
            class="m-xs"
            :to="item.url"
          >
            <Section :data="item" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { single } from '@/guides/endpoints/quali'
import { useRoute } from 'vue-router'
import BackBtn from '@/components/BackBtn.vue'
import MailtoCta from '@/components/MailtoCta.vue'
import ArticleHeader from '@/components/article/Header.vue'
import Section from '@/components/inventory/Section.vue'

export default defineComponent({
  name: 'quali',
  components: {
    BackBtn,
    MailtoCta,
    ArticleHeader,
    Section,
  },
  setup() {
    const data = ref()
    const route = useRoute()
    const slug = computed(() => route?.params?.slug)

    const completeQuali = {
      label: 'Envie de rajouter des informations sur ce Quali ?',
      mailto: 'aurelie.moreau@guides.be',
    }

    const fetch = () => {
      if (!slug.value) {
        return
      }

      single(slug.value).then(r => {
        data.value = r.data.content
      })
    }

    watch(() => slug.value, fetch)

    fetch()

    return {
      data,
      completeQuali,
    }
  },
})
</script>

<style lang="scss" scoped>
.quali {
  position: relative;
  background-color: $c-white;

  &:not(.no-picture) {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 46.8rem;
      background-color: $athens-gray;
      z-index: 0;

      .branch-horizon & {
        background-color: $branch-horizon;
      }

      .branch-route & {
        background-color: $branch-route;
      }
    }
  }
}

.quali__extended {
  background-color: $athens-gray;
}

[class*='quali-inner--'],
.quali-inner {
  position: relative;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  padding: $spacing * 3 0;
  z-index: 0;

  &:nth-child(odd) {
    h3 {
      margin-bottom: $spacing * 0.75;
    }
  }

  @include mq(s) {
    width: calc(100% - #{$spacing * 4});
  }

  &[class*='--large'] {
    max-width: 60rem;
    @include mq(m) {
      max-width: 72rem;
    }
  }
}

[class*='quali__content--'],
.quali__content {
  margin: 0 $spacing * 1.35;

  @include mq(m) {
    margin: 0;
  }
}

.quali__content h3 {
  margin-top: $spacing * 1.5;
  margin-bottom: $spacing / 4;

  &:first-child {
    margin-top: 0;
  }
}

.quali__content div {
  margin: 0 0 $spacing * 1.5;
}

.quali__content ul {
  list-style: none;
  padding: 0;
  margin: 0 0 $spacing * 1.5;
}

.quali__content li {
  display: flex;

  & + li {
    margin-top: $spacing / 2;
  }
}

.quali__content li::before {
  content: '\2022';
  color: $regal-blue;
  display: inline-block;
  width: 6px;
  margin-right: $spacing / 2;
}

.quali__content__text {
  ::v-deep(p) {
    margin-bottom: $spacing / 2;
  }
}
</style>
